import 'widgets/widgetsList';
import 'core/widgetsList';
import 'harmony/widgetsList';
import 'flagship_tasting_room/widgetsList';
import 'thebarrelroom/widgetsList';
import WidgetsMgr from 'widgets/widgetsMgr';
import { getCookie } from 'widgets/toolbox/cookie';

function initWidgets(module) {
    WidgetsMgr.run();

    // @ts-ignore
    if (module.hot) {
        // @ts-ignore
        module.hot.accept(['widgets/widgetsList', 'core/widgetsList', 'harmony/widgetsList',
            'flagship_tasting_room/widgetsList', 'brand_thebarrelroom/widgetsList'], () => WidgetsMgr.restartWidgets());
    }
}

const acceptCookie = getCookie('dw_accept_cookie');
const consentCookie = getCookie('dw_consent_cookie');

if (window.monetateQ) {
    if (!acceptCookie || (acceptCookie && consentCookie)) {
        import('monetate/clientMonetate');
    }
}

if (window.isGtmEnabled) {
    const isGtmInited = window.google_tag_manager;

    if (!acceptCookie || (acceptCookie && consentCookie)) {
        if (isGtmInited) {
            import('harmony/clientGTM').then(initWidgets.bind(module));
        } else {
            document.addEventListener('gtm_loaded', function () {
                import('harmony/clientGTM').then(initWidgets.bind(module));
            });
        }
    } else {
        initWidgets(module);    
    }
} else {
    initWidgets(module);
}
